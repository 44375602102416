<template>
  <div
    class="item d-flex flex-column"
    :class="{
      'cursor-pointer': !hasNotes && canEdit,
      'item-has-notes': hasNotes
    }"
    @click="emitAddDialog"
  >
    <div
      class="flex"
      :class="{ 'cursor-pointer': canEdit }"
      @click="showDialogAddNote"
    >
      <div class="flex-inner">
        <ib-tooltip
          effect="dark"
          placement="bottom"
          popper-class="plan-popper"
        >
          <div
            slot="content"
            class="tooltip-content"
          >
            <p
              class="tooltip-title"
              v-text="`${$t(`pages.ideaPlan.sections.${data.title}`)}`"
            />
            <div v-html="$t(`pages.ideaPlan.sectionsDescription.${data.title}`)" />
          </div>

          <img
            :src="baseUrl + data.icon"
            :alt="data.title"
          >
        </ib-tooltip>

        <h4
          :class="{
            'item-title': !hasNotes,
            'item-has-notes-title': hasNotes
          }"
          v-text="`${$t(`pages.ideaPlan.sections.${data.title}`)}`"
        />
      </div>

      <el-button
        v-if="canEdit"
        class="item-add-note-button d-md-none d-xl-block"
        type="plus-round-xs"
        icon="el-icon-plus"
        @click="showDialogAddNote"
      />
    </div>

    <template v-if="hasNotes">

      <ib-draggable
        class="flex-grow-1"
        :list="data.notes"
        :options="{disabled: !canEdit}"
        group="notes"
        draggable=".note"
        @change="onChangeOrder"
      >
        <div
          v-for="note in data.notes"
          :key="note.id"
          class="note"
          :class="note.color + [canEdit ? ' cursor-move' : '']"
          @click="showDialogEditNote(note)"
        >

          <h4 v-text="whiteSpace(note.title)" />
          <div v-html="note.text" />

          <div v-if="canEdit" class="edit-bottom">
            <a @click="showDialogEditNote(note)">
              <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill="#fff" fill-rule="evenodd" d="M1.5 12.78l8.34-8.575 2.14 2.197-8.35 8.56H1.5v-2.183zM12.125 1.853l2.15 2.203-1.23 1.26L10.9 3.119l1.225-1.265zM.75 16.498h3.19c.2 0 .39-.082.53-.226L15.865 4.6a.777.777 0 000-1.086L12.655.225a.74.74 0 00-.53-.225.74.74 0 00-.53.225L.22 11.92a.796.796 0 00-.22.543v3.268c0 .425.335.768.75.768z"/></svg>
            </a>
            <a @click.stop="showDialogRemoveNote(note)">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M14.65 2.006c-.504 0-1.009.189-1.388.568L2.576 13.264a1.978 1.978 0 000 2.777l5.385 5.385a1.957 1.957 0 001.578.55V22H22v-2h-9.836l9.26-9.264a1.978 1.978 0 000-2.777l-5.385-5.385a1.957 1.957 0 00-1.389-.568zm0 2.008l5.334 5.336-4.623 4.623-5.334-5.334 4.623-4.625zm-6.037 6.039l5.334 5.334-4.597 4.6-5.334-5.337 4.597-4.597z"/></svg>
            </a>
          </div>

        </div>
      </ib-draggable>

    </template>

    <template v-else>
      <ib-draggable
        class="flex-grow-1"
        group="notes"
        :list="data.notes"
        @change="onChangeOrder"
      >
        <div
          class="placeholder-text"
          @click="showDialogAddNote"
          v-html="$t(`pages.ideaPlan.sectionsDescription.${data.title}`)"
        />
      </ib-draggable>
    </template>

    <el-button
      v-if="canEdit"
      class="item-add-note-button-bottom d-md-block d-xl-none"
      type="plus-round-xs"
      icon="el-icon-plus"
      @click="showDialogAddNote"
    />

  </div>
</template>

<script>
import MixinIdeaRoles from '@/mixins/permissions/ideaRoles'
import { mapActions } from 'vuex'

export default {
  name: 'SectionItem',

  mixins: [
    MixinIdeaRoles
  ],

  props: {
    data: {
      type: Object,
      default: () => {}
    }
  },

  data () {
    return {
      baseUrl: process.env.VUE_APP_BASE_URL
    }
  },

  computed: {
    hasNotes () {
      return (this.data.notes && this.data.notes.length > 0)
    }
  },

  methods: {
    ...mapActions('idea', [
      'updateOrderNumber'
    ]),

    whiteSpace (a) {
      return a.replace(/^\s+|\s+$/gm, '')
    },

    emitAddDialog () {
      if (!this.hasNotes) {
        this.showDialogAddNote()
      }
    },

    showDialogAddNote () {
      this.$emit('dialog-add-show', this.data)
    },

    showDialogEditNote (note) {
      this.$emit('dialog-edit-note', this.data, note)
    },

    showDialogRemoveNote (note) {
      this.$emit('dialog-remove-note', note)
    },

    onChangeOrder (data) {
      this.updateOrderNumber({
        type: 'ideaPlan',
        data,
        oldOrderData: data.notes,
        mutation: 'ideaPlan/updateSection',
        additional: this.data.id
      })
    }
  }
}
</script>
<style scoped lang="scss">
.item {
  background-color: $color-white;
  transition: all 0.25s;
}

.item:hover,
.item-has-notes:hover
{
  background-color: $color-blue-hover;
}

.item-title,
.placeholder-text >>> p {
  color: $color-navy;
}

</style>
